import axios from "axios";

export default {
  state: {
    actions: null,
    action: null,
  },
  getters: {
    actions: (state) => state.actions,
    action: (state) => state.action,
    kidBalance: (state) => (kidId) => {
      state.actions
        ?.filter((a) => a.kidId === kidId)
        ?.reduce((accumulator, value) => {
          return accumulator + value.amount;
        }, 0)
        .toFixed(2);
    },
  },
  mutations: {
    "actions/set": (state, data) => {
      state.actions = data;
    },
    "actions/unset": (state) => {
      state.actions = null;
    },
    "actions/create": (state, data) => {
      if (!state.actions) state.actions = [];
      state.actions.push(data);
    },

    "action/set": (state, data) => {
      state.action = state.actions.find((action) => action._id == data);
    },
    "action/unset": (state) => {
      state.action = null;
    },
    "action/update": (state, { newAction }) => {
      state.actions = state.actions.map((item) => {
        if (item._id === newAction._id) {
          return { ...item, ...newAction };
        }
        return item;
      });
      state.action = newAction;
    },
    "action/delete": (state, action) => {
      state.actions = state.actions.filter((a) => a._id !== action._id);
    },
  },
  actions: {
    "actions/get/company": async (context, dateRange) => {
      try {
        dateRange = `${dateRange
          .map((item) => `dateRange[]=${item}`)
          .join("&")}`;
        let { data } = await axios.get(`/api/action/company?${dateRange}`);
        context.commit("actions/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant red action";
      }
    },
    "actions/get": async (context, payload) => {
      try {
        let { data } = await axios.get(`/api/action/?kidId=${payload}`);
        context.commit("actions/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant red action";
      }
    },
    "actions/create": async (context, payload) => {
      try {
        const { data } = await axios.post(`/api/action`, payload);
        context.commit("actions/create", data);
        context.commit("auth/updateKidBalance", {
          kidId: payload.kidId,
          newBalance: context.getters.kidCurrentBalance + payload.amount,
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant add new action";
      }
    },
    "action/update": async (context, payload) => {
      try {
        const newAmount = payload.oldAction.amount - payload.newAction.amount;
        await axios.put(`/api/action/${payload.kidId}`, payload.newAction);
        context.commit("action/update", payload);
        context.commit("auth/updateKidBalance", {
          kidId: payload.kidId,
          newBalance: context.getters.kidCurrentBalance - newAmount,
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant updata action";
      }
    },
    "action/delete": async (context, payload) => {
      try {
        await axios.delete(`/api/action/${payload._id}`);
        context.commit("action/delete", payload);
        context.commit("auth/updateKidBalance", {
          kidId: payload.kidId,
          newBalance: context.getters.kidCurrentBalance - payload.amount,
        });
        context.commit("invokeSnack", {
          msg: "נמחק בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant delete action";
      }
    },
  },
};
