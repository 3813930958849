// import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";

// Vue.use(Vuetify);

// export default new Vuetify({
//   rtl: true,
// });

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import He from "vuetify/lib/locale/he";

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { He },
    current: "He",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#7f00ff",
        lightPurple: "#984BFF",
        veryLightPurple: "#f9f6ff",
        lightGrey: "#F8F8F8",
        darkerGrey: "#9F9F9F",
        primaryText: "#707070",
        lightYellow: "#FFFAF6",
        boldText: "#3A3A3A",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        veryWhite: "#ffffff",
      },
    },
  },
});
