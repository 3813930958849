<template>
  <v-app :class="`${isAdmin ? 'desktop' : 'mobile'}`">
    <drawer v-if="isDrawerOpen" v-click-outside="closeDrawer" />

    <v-subheader v-if="showHeader" dir="ltr" class="lightPurple">
      <div id="link">
        <v-btn v-if="!loading" icon @click="showAbout = true">
          <v-icon color="white">mdi-information-outline</v-icon>
        </v-btn>
      </div>
    </v-subheader>

    <loading-page v-if="loading" :modeScreen="isAdmin ? 'desktop' : 'mobile'" />

    <v-main
      :class="`main ${isAdmin ? 'desktop' : ''} ${
        !$vuetify.breakpoint.mobile && !isAdmin && isDrawerOpen
          ? 'desktop-mode'
          : ''
      }`"
      v-else
    >
      <admin-navbar v-if="isShowDrawer" />
      <router-view />
    </v-main>
    <v-footer app class="center-all mb-10 footer">
      <v-bottom-sheet v-model="showAbout" max-width="300px">
        <about />
      </v-bottom-sheet>
      <v-alert
        v-if="snack"
        :type="snack.type"
        width="400px"
        absolute
        bottom
        class="center-all"
      >
        {{ snack.msg }}
      </v-alert>
    </v-footer>
  </v-app>
</template>
<script>
import About from "@/components/About.vue";
import Drawer from "@/components/Drawer.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import LoadingPage from "@/views/LoadingPage.vue";

export default {
  name: "App",
  components: { LoadingPage, About, Drawer, AdminNavbar },
  data() {
    return {
      showAbout: false,
    };
  },
  computed: {
    showHeader() {
      return (
        this.$route.name !== "Login" &&
        this.$route.name !== "NotFound" &&
        this.$route.name !== "Register" &&
        !this.$route.name?.includes("Admin") &&
        !this.$route.name?.includes("Company")
      );
    },
    isDrawerOpen() {
      return this.$store.getters.isDrawerOpen;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    snack() {
      return this.$store.getters.snack;
    },
    loading() {
      return this.$store.getters.loading;
    },
    route() {
      return this.$route.name;
    },
    isShowDrawer() {
      return this.isAdmin || this.$store.getters.isAuth;
    },
  },
  methods: {
    closeDrawer() {
      if (this.isDrawerOpen) {
        this.$store.commit("drawer/set", false);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit("loading/end");
    }, 1500);
  },
};
</script>
<style scoped lang="scss">
.mobile {
  max-width: 500px;
  margin: 0px auto;
}
.footer {
  background: center !important;
}
#link {
  position: relative;
  // direction: rtl;
}
.desktop {
  width: auto;
  // background-color: var(--primary);
}
.desktop-mode {
  padding: 0px !important;
}
</style>
