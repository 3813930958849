import { mergeObjectsByField } from "@/utils/marge";
import axios from "axios";
import { cloneDeep } from "lodash";

export default {
  state: {
    credit: null, //chosen credit
    credits: [],
    filtered: [],
  },
  getters: {
    credits: (state) => state.credits,
    creditsByCompany: (state) => (companyId) =>
      state.credits.filter((c) => c.company._id === companyId),
    creditsApproved: (state) =>
      state.credits.filter((c) => c.status === "approved"),
    creditsMerged: (state) =>
      mergeObjectsByField(cloneDeep(state.credits), "company._id", [
        "amount",
        "sumUses",
      ]) || [],
    creditByStatusAndIdMerged: (state) => (status, companyId) =>
      mergeObjectsByField(
        cloneDeep(
          state.credits.filter(
            (c) => c.company._id === companyId && c.status === status,
          ),
        ),
        "company._id",
        ["amount", "sumUses"],
      ) || [],
    creditsApprovedMerged: (state) =>
      mergeObjectsByField(
        cloneDeep(state.credits.filter((c) => c.status === "approved")),
        "company._id",
        ["amount", "sumUses"],
      ) || [],
    creditsPadding: (state) =>
      state.credits.filter((c) => c.status === "padding"),
    credit: (state) => state.credit,
    creditFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all credits
    "credits/set": (state, payload) => {
      state.credits = payload;
      state.filtered = [...state.credits];
    },
    //sets one credit
    "credit/set": (state, payload) => (state.credit = payload),
    //filters the credit's array by credit's key and credit's val
    "credits/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.credits]
        : state.credits.filter((f) => f[key] === val);
    },
    //store one credit
    "credit/store": (state, payload) => state.credits.push(payload),
    //destroys one credit
    "credit/destroy": (state, id) =>
      (state.credits = state.credits.filter((item) => {
        return item._id !== id;
      })),
    //updates one credit
    "credit/update": (state, payload) => {
      state.credits = state.credits.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all credits
    "credit/index": async (context) => {
      try {
        const { data } = await axios.get("/api/credit");
        context.commit("credits/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    "credit/index/company": async (context, { status, dateRange }) => {
      try {
        status = status.map((item) => `status[]=${item}`).join("&");
        if (dateRange?.length >= 0) {
          dateRange = `&${dateRange
            .map((item) => `dateRange[]=${item}`)
            .join("&")}`;
        }
        const { data } = await axios.get(
          `/api/credit/company/status?${status}${dateRange ?? ""}`,
        );
        context.commit("credits/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //fetch one credit by id
    "credit/show": async (context, id) => {
      try {
        let { data } = await axios.get("/api/credit/" + id);
        context.commit("credit/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //stores one credit
    "credit/store": async (context, payload) => {
      try {
        let { data } = await axios.post("/api/credit", payload);
        context.commit("credit/store", data);
      } catch (e) {
        console.log(e);
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //destroys one credit
    "credit/destroy": async (context, id) => {
      try {
        await axios.delete("/api/credit/" + id);
        context.commit("credit/destroy", id);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //destroys one credit
    "credit/destroy/kid": async (context, { creditId, kidId }) => {
      try {
        const { data } = await axios.delete(
          `/api/credit/${creditId}/kid/${kidId}`,
        );
        context.commit("kid/set", data);
        context.commit("auth/updateKid", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //updates one credit by its id
    "credit/update": async (context, payload) => {
      try {
        await axios.put("/api/credit/" + payload._id, payload);
        context.commit("credit/update", {
          _id: payload._id,
          amount: payload.amount,
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //updates one credit by its id
    "credit/update/kid": async (context, payload) => {
      try {
        await axios.put("/api/credit/kid/" + payload._id, payload);
        context.commit("credit/update", {
          _id: payload._id,
          amount: payload.amount,
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //update credit status by its id
    "credit/update/company": async (context, { creditId, userId, status }) => {
      try {
        const { data } = await axios.put(
          `/api/credit/${creditId}/user/${userId}`,
          { status },
        );
        context.commit("credit/update", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
  },
};
