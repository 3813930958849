import axios from "axios";

export default {
  state: {
    users: null,
  },
  getters: {
    users: (state) => {
      return state.users;
    },
  },
  mutations: {
    "admin/set": (state, data) => {
      state.users = data;
    },
    "admin/removeUser": (state, email) => {
      state.users = state.users.filter((item) => item.email !== email);
    },
  },
  actions: {
    "admin/user": async (context) => {
      try {
        let { data } = await axios.get(`/api/users`);
        context.commit("admin/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });

        throw "Cant log in";
      }
    },
    "admin/change-password/user": async (context, payload) => {
      try {
        await axios.put(`/api/users/update-password/${payload._id}`, payload);
        context.commit("invokeSnack", {
          msg: "עודכן בהצלחה",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });

        throw "error updating password";
      }
    },
  },
};
