<template>
  <v-navigation-drawer app v-model="openModal" right class="pt-5">
    <v-list shaped>
      <v-list-item-group v-model="selectedItem" color="primary" nav dense>
        <v-list-item
          v-for="(item, i) in items"
          @click="goRoute(item.route)"
          :disabled="selectedItem === i"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.title" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-if="!isAdmin">
      <v-divider />
      <div class="py-3 d-flex align-center justify-center">
        <v-icon color="red" @click="logout" v-text="'mdi-power'" />
      </div>
      <v-divider />
    </template>
  </v-navigation-drawer>
</template>
<script>
import { createSwal } from "@/actions.js";
export default {
  name: "Drawer",
  computed: {
    items() {
      if (this.isRegularUser) {
        const userItems = [
          {
            title: "ילדים",
            icon: "mdi-human-male-female-child",
            route: "Home",
          },
          {
            title: "הטענת בית העסק",
            icon: "mdi-store",
            route: "UserCredits",
          },
        ];
        return userItems;
      }

      if (this.isAdmin) {
        const adminItems = [
          {
            title: "ניהול משתמשים",
            icon: "mdi-account-multiple",
            route: "AdminUserManager",
          },
          {
            title: "ניהול חברות",
            icon: "mdi-shopping-outline",
            route: "AdminCompanyManager",
          },
        ];
        return adminItems;
      }

      if (this.isCompany) {
        const companyItems = [
          {
            title: "בקשות תשלום",
            icon: "mdi-account-credit-card-outline",
            route: "PaymentRequests",
          },
          {
            title: "היסטורית תשלומים",
            icon: "mdi-shopping-outline",
            route: "PurchaseHistory",
          },
          {
            title: "הסטורית אישורי תשלום",
            icon: "mdi-contactless-payment-circle",
            route: "PaymentConfirmations",
          },
        ];
        return companyItems;
      }
      return [];
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isCompany() {
      return this.$store.getters.isCompany;
    },
    isRegularUser() {
      return this.$store.getters.isRegularUser;
    },
    selectedItem: {
      get() {
        return this.items.findIndex((i) => i.route === this.$route.name);
      },
      set(value) {
        return value;
      },
    },

    openModal: {
      get() {
        return this.$store.getters.isDrawerOpen;
      },
      set(val) {
        this.$store.commit("drawer/set", val);
      },
    },
  },
  methods: {
    goRoute(r) {
      this.$router.push({ name: r });
      this.openModal = false;
    },
    async logout() {
      let { isConfirmed } = await createSwal({
        title: `רוצה לצאת?`,
        icon: "question",
        confirmColor: "red",
      });
      if (isConfirmed) {
        await this.$store.dispatch("auth/logout");
        if (this.isCompany) {
          this.$router.push({
            name: "CompanyLogin",
          });
        } else {
          this.$router.push({
            name: "Login",
          });
        }
      }
    },
  },
};
</script>
