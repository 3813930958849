const Swal = require("sweetalert2");
function createSwal({ icon, title, color, text, closeable, lang = "he" }) {
  if (!color) color = "#2196F3";
  if (closeable === null || closeable === undefined) closeable = true;
  let direction = lang === "he" ? "rtl" : "ltr";
  return Swal.fire({
    icon,
    title: `<p style="direction: ${direction} !important;">${title}</p>`,
    html: text
      ? `<p style="direction: ${direction} !important;">${text}</p>`
      : "",
    confirmButtonText: lang === "he" ? "אישור" : "Ok",
    confirmButtonColor: color,
    showCancelButton: true,
    cancelButtonText: lang === "he" ? "ביטול" : "Cancel",
    titleColor: "ee44aa",
    allowOutsideClick: closeable,
  });
}
function successSwal({ title, timer = 1500, showConfirmButton = false }) {
  return Swal.fire({
    icon: "success",
    title,
    showConfirmButton,
    timer,
  });
}
function errorSwal({ title, timer = 1500, showConfirmButton = false }) {
  return Swal.fire({
    icon: "error",
    title,
    showConfirmButton,
    timer,
  });
}
function createInputSwaldata(title, color = "#2196F3") {
  return {
    title,
    input: "text",
    showCancelButton: true,
    confirmButtonColor: color,
    inputValidator: (value) => {
      if (!value) return "נא להזין פרטים נכונים";
    },
    showConfirmButton: true,
    confirmButtonText: "אישור",
  };
}

const getAdminCookie = () => {
  let cookies = document.cookie.split(";");
  let adminCookie;

  let find;
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    find = cookie.search("admin=");
    if (find != -1) {
      adminCookie = cookie;
      break;
    }
  }
  if (adminCookie) {
    adminCookie = adminCookie.replace(" admin=", "");
    return adminCookie;
  } else {
    return false;
  }
};

function downloadDataToExcel($tableData) {
  console.log($tableData);
  function createTable(tableData) {
    var table = document.createElement("table");
    var tableBody = document.createElement("tbody");

    var top_obj = {
      count: " ",
      name: "שם",
      phone: "טלפון",
      email: "אימייל",
      totalScore: "ניקוד",
      campaigns: "קמפיין",
    };
    let myData = "";
    var tableData_arr = [];
    tableData_arr.unshift(top_obj);

    var count = 0;

    for (var i = 0; i < tableData.length; i++) {
      console.log(tableData[i]);
      var obj = {
        count: ++count,
        name: tableData[i].username,
        phone: tableData[i].phone,
        email: tableData[i].email,
        totalScore: tableData[i].totalScore,
        playedGames: tableData[i].campaigns,
      };
      tableData_arr.push(obj);
    }

    for (i = 0; i < tableData_arr.length; i++) {
      var row = document.createElement("tr");
      let rowData = tableData_arr[i];

      myData +=
        rowData.count +
        ".  שם:" +
        rowData.name +
        " טלפון:" +
        rowData.name +
        " אימייל:" +
        rowData.phone +
        " ניקוד כללי:" +
        rowData.totalScore +
        " קמפיין:" +
        rowData.campaigns +
        "\n";

      for (var j in rowData) {
        var cell = document.createElement("td");

        cell.appendChild(document.createTextNode(rowData[j]));
        row.appendChild(cell);
      }
      tableBody.appendChild(row);
    }
    console.log(myData);
    table.appendChild(tableBody);
    // document.body.appendChild(table);
    return table;
  }

  var titleText = "טבלת משתמשים";

  function exceller(table, titleText) {
    //UI
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><?xml version="1.0" encoding="UTF-8" standalone="yes"?><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };

    var ctx = {
      worksheet: name || "",
      table: table,
    };
    //$('#toExcel').remove();

    var link = document.createElement("a");
    link.href = uri + base64(format(template, ctx));
    link.download = titleText + ".xls";
    link.click();

    //download(uri + base64(format(template, ctx), titleText, 'application/vnd.ms-excel'));
    //window.open(uri + base64(format(template, ctx)));
  }
  exceller(createTable($tableData).outerHTML, titleText);

  // download(createTable($tableData).outerHTML, titleText, 'application/vnd.ms-excel')
}

/**
 * enters cloudinary params to the link and send it back
 * @param {*} link - the cloudinary link
 * @param {*} urlParams - array of params to add to the link
 */
function cloudinaryLinkManipulator(link, urlParams) {
  let imageArr = link.split("upload");
  let linkToSend = imageArr[0] + "upload/";
  if (urlParams) urlParams.forEach((param) => (linkToSend += `${param}`));
  linkToSend += imageArr[1];
  return linkToSend;
}

function setCampaignMeta(campaign) {
  document.title = campaign.name;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", campaign.description);
  document
    .querySelector("#site-logo")
    .setAttribute("href", `/uploads/campaigns/${campaign.imagePath}`);
}

module.exports = {
  getAdminCookie,
  downloadDataToExcel,
  cloudinaryLinkManipulator,
  createSwal,
  successSwal,
  errorSwal,
  createInputSwaldata,
  setCampaignMeta,
};
