import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "./style/global.scss";
import './registerServiceWorker'

const preload = async () => {
  try {
    await store.dispatch("auth/get");
  } catch (e) {
    console.log("not logged in");
  }

  Vue.use(VueSweetalert2);
  Vue.config.productionTip = false;

  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};

preload();
