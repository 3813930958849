const getKey = (obj, field) => {
  const keys = field.split(".");

  // Iterate through each part of the key path to access the nested key
  let result = obj;
  for (const key of keys) {
    result = result[key];
  }
  return result;
};

/**
 *
 * @param {Array.<Object>} arr - An array of objects to be merged
 * @param {string} field - The unique identifier by which the objects are merged
 * @param {Array.<string>} fields - Fields that connect them
 * @returns
 */
export const mergeObjectsByField = (arr = [], field = "", fields = [""]) => {
  const result = arr.reduce((acc, obj) => {
    const key = getKey(obj, field);
    if (!acc[key]) {
      acc[key] = obj;
    } else {
      fields.forEach((f) => {
        obj[f] = acc[key][f] + obj[f];
      });
      acc[key] = { ...acc[key], ...obj };
    }
    return acc;
  }, {});

  const arrayResult = [];
  Object.keys(result).forEach((key) => arrayResult.push(result[key]));

  return arrayResult;
};
