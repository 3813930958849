import axios from "axios";

export default {
  state: {
    isAuth: false,
    user: null,
    kid: null,
  },
  getters: {
    isCompany: (state) => state.user?.role === 2,
    isRegularUser: (state) => state.user?.role === 1,
    isAdmin: (state) => state.user?.role === 0,
    user: (state) => state.user,
    kid: (state) => state.kid,
    kidCurrentBalance: (state) => state.kid?.balance,
  },
  mutations: {
    "auth/set": (state, data) => {
      state.isAuth = true;
      state.user = data;
    },
    "auth/logout": (state) => {
      state.isAuth = true;
      state.user = null;
    },
    "auth/updateKid": (state, kid) => {
      state.user.kids.splice(
        state.user.kids.findIndex((k) => k._id === kid._id),
        1,
        kid,
      );
    },
    "auth/updateKidBalance": (state, { kidId, newBalance }) => {
      const kid = state.user.kids.find((k) => k._id === kidId);
      kid.balance = newBalance;
    },
    "auth/removeKid": (state, kidId) => {
      state.user.kids = state.user.kids.filter((item) => item._id !== kidId);
    },
    "kid/setById": (state, id) =>
      (state.kid = state.user.kids.find((k) => k._id === id)),
    "kid/set": (state, kid) => (state.kid = { ...kid }),
    "kid/unset": (state) => (state.kid = null),
    "allowance/update": (state, payload) => {
      state.user.kids.find((k) => k._id === payload.kidId).allowance[0] =
        payload;
    },
  },
  actions: {
    "auth/login": async (context, payload) => {
      try {
        const { data } = await axios.post("/auth/login", payload);
        context.commit("auth/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant log in";
      }
    },
    "auth/login-company": async (context, payload) => {
      try {
        const { data } = await axios.post("/auth/login-company", payload);
        context.commit("auth/set", data);
        context.commit("company/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant log in";
      }
    },
    "auth/register": async (context, payload) => {
      try {
        await axios.post("/auth/register", payload);
        context.commit("invokeSnack", {
          msg: "נרשמת בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw e.response.data.he;
      }
    },
    "auth/get": async (context) => {
      try {
        let { data } = await axios.post(`/auth/checkLogin`);
        context.commit("auth/set", data);
      } catch (e) {
        throw "Cant log in";
      }
    },
    "auth/check-login-company": async (context) => {
      try {
        let { data } = await axios.post(`/auth/check-login-company`);
        context.commit("auth/set", data);
        context.commit("company/set", data);
      } catch (e) {
        console.log("Company cant log in");
      }
    },
    "auth/logout": async (context) => {
      try {
        await axios.post("/auth/logout");
        context.commit("auth/logout");
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant log out";
      }
    },
    "user/update": async (context, payload) => {
      try {
        await axios.put("/api/users", payload);
        const user = context.getters.user;
        context.commit("auth/set", {
          ...user,
          ...payload,
        });
        context.commit("invokeSnack", {
          msg: "עודכן בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant add-kid";
      }
    },
    "auth/reset-password": async (context, payload) => {
      try {
        await axios.post("/auth/reset-password", payload);
        context.commit("invokeSnack", {
          msg: "נשלח מייל לאיפוס סיסמה, בדוק את המייל שלך",
          type: "success",
          time: 5000,
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant reset password";
      }
    },
    "auth/change-password": async (context, payload) => {
      try {
        await axios.put("/api/users/update-password", payload);

        context.commit("invokeSnack", {
          msg: "עודכן בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant change password";
      }
    },
    "user/delete": async (context, email) => {
      try {
        await axios.delete(`/api/users/${email}`);
        context.commit("admin/removeUser", email);
        context.commit("invokeSnack", {
          msg: "נמחק בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant delete kid";
      }
    },
    "user/add-kid": async (context, payload) => {
      try {
        const { data } = await axios.post("/api/users/kid", payload);
        context.commit("auth/set", data);
        context.commit(
          "kid/set",
          data.kids.find((kid) => kid.name === payload.kids[0]["name"]),
        );
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant add-kid";
      }
    },
    "user/delete-kid": async (context, { kidId }) => {
      try {
        await axios.delete(`/api/users/kid/${kidId}`);
        context.commit("auth/removeKid", kidId);
        context.commit("invokeSnack", {
          msg: "נמחק בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant delete kid";
      }
    },
    "user/edit-kid": async (context, payload) => {
      try {
        let { data } = await axios.put(
          `/api/users/kid/${payload.kidId}`,
          payload,
        );
        context.commit("kid/set", data);
        context.commit("auth/updateKid", data);
        context.commit("invokeSnack", {
          msg: "עודכן בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant edit kid";
      }
    },
    "user/get-kid": async (context, payload) => {
      try {
        let { data } = await axios.get(`/api/users/kid/${payload}`);
        context.commit("kid/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant find kid";
      }
    },
    "allowance/create": async (context, payload) => {
      try {
        const { data } = await axios.post(
          `/api/allowance/` + payload.kidId,
          payload,
        );
        context.commit("allowance/update", { ...payload, ...data });

        context.commit("invokeSnack", {
          msg: "נוצר בהצלחה!",
          type: "success",
        });
        context.commit("allowance/create", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant add new allowance";
      }
    },
    "allowance/update": async (context, payload) => {
      try {
        await axios.put(
          `/api/allowance/${payload.kidId}/${payload.allowanceId}`,
          payload,
        );
        context.commit("invokeSnack", {
          msg: "עודכן בהצלחה!",
          type: "success",
        });
        context.commit("allowance/update", payload);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant updata allowance";
      }
    },
    "allowance/delete": async (context, { allowanceId, kidId }) => {
      try {
        await axios.delete(`/api/allowance/${kidId}/${allowanceId}`);
        context.commit("allowance/update", { kidId, allowanceId });
        context.commit("invokeSnack", {
          msg: "נמחק בהצלחה!",
          type: "success",
        });
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
        throw "Cant delete allowance";
      }
    },
  },
};
