import axios from "axios";

export default {
  state: {
    company: null, //chosen company
    companies: [],
    filtered: [],
  },
  getters: {
    cities: (state) => state.companies.map((c) => c.city),
    companies: (state) => state.companies,
    company: (state) => state.company,
    companyFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all companies
    "companies/set": (state, payload) => {
      state.companies = payload;
      state.filtered = [...state.companies];
    },
    //sets one company
    "company/set": (state, payload) => (state.company = payload),
    //filters the company's array by company's key and company's val
    "companies/filter": (state, { key, val }) => {
      state.filtered = !val
        ? [...state.companies]
        : state.companies.filter((f) => f[key] === val);
    },
    //store one company
    "company/store": (state, payload) => state.companies.push(payload),
    //destroys one company
    "company/destroy": (state, id) =>
      (state.companies = state.companies.filter((item) => {
        return item._id !== id;
      })),
    //updates one company
    "company/update": (state, payload) => {
      state.companies = state.companies.map((item) => {
        if (item._id === payload._id) {
          return payload;
        }
        return item;
      });
    },
  },
  actions: {
    //fetch all companies
    "company/index": async (context) => {
      try {
        const { data } = await axios.get("/api/company");
        context.commit("companies/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //fetch one company by id
    "company/show": async (context, id) => {
      try {
        let { data } = await axios.get("/api/company/" + id);
        context.commit("company/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //stores one company
    "company/store": async (context, payload) => {
      try {
        let { data } = await axios.post("/api/company", { ...payload });
        context.commit("company/store", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //destroys one company
    "company/destroy": async (context, id) => {
      try {
        await axios.delete("/api/company/" + id);
        context.commit("company/destroy", id);
      } catch (e) {
        throw new Error(e);
      }
    },
    //updates one company by its id
    "company/update": async (context, payload) => {
      try {
        await axios.put("/api/company/" + payload._id, payload);
        context.commit("company/update", payload);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //create pay from kid
    "company/pay": async (context, payload) => {
      try {
        await axios.post("/api/company/pay/" + payload.code, payload);
        // context.commit("company/update", payload);
      } catch (e) {
        throw new Error(e);
      }
    },
    //cancel pay from kid
    "company/cancel-pay": async (context, id) => {
      try {
        await axios.post("/api/company/cancel-pay/" + id);
        // context.commit("action/delete", payload);
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
