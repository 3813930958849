import Vue from "vue";
import Vuex from "vuex";
import authModule from "./modules/authModule";
import actionsModule from "./modules/actionsModule";
import adminModule from "./modules/adminModule";
import companyModule from "./modules/companyModule";
import creditModule from "./modules/creditModule";
import qrModule from "./modules/qrModule";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snack: null,
    loading: true,
    isDrawerOpen: false,
    title: "",
  },
  getters: {
    snack: (state) => state.snack,
    loading: (state) => state.loading,
    title: (state) => state.title,
    isDrawerOpen: (state) => state.isDrawerOpen,
  },
  mutations: {
    invokeSnack: (state, snack) => {
      if (!snack.msg) return;
      state.snack = snack;
      setTimeout(() => (state.snack = null), snack.time || 1000);
    },
    "loading/start": (state) => (state.loading = true),
    "loading/end": (state) => (state.loading = false),
    "title/set": (state, title) => (state.title = title),
    "drawer/set": (state, isDrawerOpen = false) => {
      state.isDrawerOpen = isDrawerOpen;
    },
  },
  modules: {
    authModule,
    actionsModule,
    adminModule,
    companyModule,
    creditModule,
    qrModule,
  },
});
