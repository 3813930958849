import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);
const checkParamsKidId = (id, next) => {
  if (id) {
    const kid = store.getters.user.kids.find((kid) => kid._id === id);
    if (!kid) return next({ name: "NotFound" });
  }
  return true;
};
const authGuard = async (to, from, next) => {
  if (store.getters.user?.role == 0) return next({ name: "AdminUserManager" });
  if (store.getters.user) {
    checkParamsKidId(to.params.id, next);
    return next();
  }
  return next({ name: "Login" });
};

const checkLogin = async (to, from, next) => {
  if (store.getters.isAdmin) return next({ name: "AdminUserManager" });
  if (store.getters.user) return next({ name: "Home" });
  return next();
};

const authGuardAdmin = async (to, from, next) => {
  if (store.getters.isAdmin) return next();
  return next({ name: "Login" });
};

const checkLoginCompany = async (to, from, next) => {
  const toLogin = to.name === "CompanyLogin";
  await store.dispatch("auth/check-login-company");
  if (store.getters.isCompany && toLogin)
    return next({ name: "PaymentRequests" });
  if (store.getters.isCompany && !toLogin) return next();
  return toLogin ? next() : next({ name: "CompanyLogin" });
};
const removeUserBeforeLoginKid = async (to, from, next) => {
  if (store.getters.user) {
    const checkKid = checkParamsKidId(to.params.id, next);
    if (!checkKid) return;
  }
  if (store.getters.user) store.commit("auth/set", null);
  await store.dispatch("user/get-kid", to.params.id);
  return next();
};

const routes = [
  {
    path: "/admin",
    beforeEnter: authGuardAdmin,
    component: async () => await import("@/views/RouterRoot.vue"),
    children: [
      {
        path: "user-manager",
        name: "AdminUserManager",
        component: async () =>
          await import("@/views/admin/AdminUserManager.vue"),
      },
      {
        path: "company",
        name: "AdminCompanyManager",
        component: async () =>
          await import("@/views/admin/AdminCompanyManager.vue"),
      },
    ],
  },
  {
    path: "/company",
    beforeEnter: checkLoginCompany,
    component: async () => await import("@/views/RouterRoot.vue"),
    children: [
      {
        path: "login",
        name: "CompanyLogin",
        component: async () => await import("@/views/company/Login.vue"),
      },
      {
        path: "dashboard",
        name: "PaymentRequests",
        component: async () =>
          await import("@/views/company/PaymentRequests.vue"),
      },
      {
        path: "purchase-history",
        name: "PurchaseHistory",
        component: async () =>
          await import("@/views/company/PurchaseHistory.vue"),
      },
      {
        path: "payment-confirmations",
        name: "PaymentConfirmations",
        component: async () =>
          await import("@/views/company/PaymentConfirmations.vue"),
      },
      {
        path: "payment/:code",
        name: "Payment",
        props: true,
        component: async () => await import("@/views/company/Payment.vue"),
      },
    ],
  },

  {
    path: "/",
    name: "Login",
    component: async () => await import("@/views/Login.vue"),
    beforeEnter: checkLogin,
  },

  {
    path: "/register",
    name: "Register",
    component: async () => await import("@/views/Register.vue"),
    beforeEnter: checkLogin,
  },
  {
    path: "/home",
    name: "Home",
    component: async () => await import("@/views/Home.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/reset-password/:password?",
    name: "ResetPassword",
    component: async () => await import("@/views/ResetPassword.vue"),
  },
  {
    path: "/kid/:id",
    name: "Kid",
    component: async () => await import("@/views/Kid.vue"),
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/action-history/:id",
    name: "ActionHistory",
    component: async () => await import("@/views/ActionHistory.vue"),
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/user-credits",
    name: "UserCredits",
    component: async () => await import("@/views/UserCredits.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/kid-link/:id",
    name: "KidLink",
    component: async () => await import("@/views/KidLink.vue"),
    props: true,
    beforeEnter: removeUserBeforeLoginKid,
  },
  {
    path: "*",
    name: "NotFound",
    component: async () => await import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
