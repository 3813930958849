import axios from "axios";

export default {
  state: {
    qr: null, //chosen qr
  },
  getters: {
    qr: (state) => state.qr,
  },
  mutations: {
    //sets one qr
    "qr/set": (state, payload) => (state.qr = payload),
  },
  actions: {
    "qr/company/show": async (context, code) => {
      try {
        const { data } = await axios.get(`/api/qr/${code}`);
        context.commit("qr/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
    //stores one qr
    "qr/store": async (context, payload) => {
      try {
        console.log(payload);
        let { data } = await axios.post("/api/qr", payload);
        context.commit("qr/set", data);
      } catch (e) {
        context.commit("invokeSnack", {
          msg: e.response.data.he,
          type: "error",
        });
      }
    },
  },
};
