<template>
  <v-app-bar flat app>
    <v-row>
      <v-col cols="1 ">
        <v-icon v-text="'mdi-menu'" color="primary" @click="openDrawer" />
      </v-col>
      <v-col cols="10" class="d-flex justify-center align-center">
        <v-toolbar-title
          class="primary--text h3 justify-self-start"
          v-text="$store.getters.title"
        />
      </v-col>
      <v-col cols="1">
        <div class="d-flex justify-end mx-2">
          <v-icon color="red" @click="logout" v-text="'mdi-power'" />
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { createSwal } from "@/actions.js";

export default {
  name: "Navbar",

  methods: {
    openDrawer() {
      this.$store.commit("drawer/set", true);
    },

    async logout() {
      let { isConfirmed } = await createSwal({
        title: `רוצה לצאת?`,
        icon: "question",
        confirmColor: "red",
      });
      if (isConfirmed) {
        await this.$store.dispatch("auth/logout");
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>
