<template>
  <div class="loading d-flex justify-center align-center">
    <div class="img-loading">
      <img src="/imgs/admin/loading.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingPage",
  props: ["modeScreen"],
};
</script>

<style scoped>
.loading {
  height: 100vh;
  background-image: linear-gradient(#984bff, #6830e6);
}
.img-loading {
  margin: 50% auto;
}
</style>
